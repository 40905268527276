@import "~antd/dist/antd.css";

:root {
  --main-bg-color: #f0f2f5;
}

.app-content {
  margin: 30px;
  min-height: 78vh;
}

.header-title {
  color: #fff !important;
  text-align: center;
  line-height: inherit !important;
}

.table-action-button {
  margin: 0 5px;
}

.success-button {
  color: #5fc114;
  background-color: inherit;
  border: 1px solid #5fc114;
}

.success-button:hover {
  color: #83c84e;
  background-color: inherit;
  border: 1px solid #83c84e;
}

.icon-button {
  margin-right: 5px;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.left-icon {
  margin-right: 5px;
}

.m-left-small {
  margin-left: 5px;
}

.m-right-small {
  margin-right: 5px;
}

.m-bottom-medium {
  margin-bottom: 14px;
}

.auth-layout {
  background-color: #fff;
}

.auth-footer {
  color: #fff;
  text-align: right;
  background-color: #b2c9e0;
  height: 80px;
}

.center-button {
  display: block;
  margin: auto;
}



.flex-wrap {
  flex-wrap: wrap;
}

/* overRides  */
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45)
}

.ant-steps-item {
  flex: none;
  -ms-flex: none;
}

.timeline-item-consultations .ant-tree-list-holder-inner {
  background: #f0f2f5;
}
