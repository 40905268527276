.header {
  background-color: #fff !important;
  padding: 0 !important;
}

.header-top {
  box-shadow: 0 2px 8px #e2e5e8;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.header-actions {
  display: flex;
  align-items: center;
  padding-right: 60px;
  flex: 1;
  justify-content: flex-end;
}

#logo {
  height: 42px;
  padding-left: 40px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px;
  font-family: Avenir, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji", sans-serif;
  line-height: 64px;
  white-space: nowrap;
  text-decoration: none;
}

#logo img {
  position: relative;
  height: 42px;
}

.full-logo {
  margin-left: 45px;
  padding: 5px;
  max-width: 69px;
  margin-top: 10px;
}

@media (max-width: 600px) {
  .header-actions {
    margin-left: 12px;
    padding-right: 0px;
  }

  .header-top {
    justify-content: unset;
  }
}
