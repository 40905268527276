.add-antecedent-card {
  /* max-width: 50%; */
  margin: auto;
}

.add-antecedent-card .ant-input {
  max-width: 50%;
}

.add-antecedent-card .ant-card-head-title {
  color: #fff;
  text-align: center;
}

.add-antecedent-card .ant-card-head {
  background: #6394f9;
}

.dmi-treatment-reminder {
  border: 1px solid #f0f0f0;
  padding: 10px;
  margin-bottom: 10px;
}


.rotate-0 .image-gallery-image {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}

.rotate-90 .image-gallery-image {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.rotate-180 .image-gallery-image {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.rotate-270 .image-gallery-image {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}

.image-gallery-slide {
  background-color: inherit;
  position: absolute;
  height: auto;
  top: inherit;
  left: inherit;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 120px);
}
