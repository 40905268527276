.sidebar-menu-item {
align-items: center;
font-size: 12px;
padding: 4px !important;
}

.sidebar {
    background-color: #fafafc;
}

.sidebar .ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 52px;
    line-height: 40px;
}


.sidebar .ant-divider-horizontal {
    margin: 12px 0;
}