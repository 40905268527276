.message--sent {
  position: absolute;
  right: 3.15rem;
  bottom: -1.075rem;
  display: flex;
  align-items: center;
}

.message--sent svg {
  margin-left: 0.25rem;
}

.message--sent-date {
  position: absolute;
  left: 3.75rem;
  bottom: -1.075rem;
}

.message-text-contact {
  display: flex;
  align-items: flex-start;
  border-radius: 0.625rem;
  padding: 0.625rem;
  font-size: 1rem;
  margin: -0.625rem 0 0.25rem 0.75rem;
  background-color: #f0f2f5;
  word-wrap: anywhere;
}

.message:not(:first-child) {
  margin-top: 2rem;
}
.message:first-child {
  margin-top: 1rem;
}

.message-text-user {
  display: flex;
  align-items: flex-start;
  border-radius: 0.625rem;
  padding: 0.625rem;
  margin: -0.625rem 0.75rem 0.25rem 0;
  font-size: 1rem;
  background-color: #1890ff;
  color: #fff;
  word-wrap: anywhere;
}

.message-user-receiver {
  flex-direction: row-reverse;
}

.message {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  position: relative;
}

.message > .ant-avatar.ant-avatar-icon {
  flex-shrink: 0;
}

.contacts-list {
  max-height: 320px;
  overflow: auto;
}

.contacts-list .ant-list-item {
  cursor: pointer;
  padding-left: 10px;
  padding-top: 18px;
  border: 30px
}

.contacts-list .ant-list-item:hover {
  background: #d1d7e1;
}

.chat-tabs-container {
  max-width: 300px;
  flex: 1;
}


.chat-tabs-container .ant-card-body {
  padding: 0;
}

.selected-contact {
  background: #D454601A;
}

.selected-contact:hover {
  background: #D454601A !important;
}

.chat-tabs-container .ant-tabs-nav-wrap {
  padding: 0 24px;
}

.unseen-message {
  margin-left: 8px;
  width: 0.6125rem;
  height: 0.6125rem;
  background-color: #ea4243;
  border-radius: 25px;
}
