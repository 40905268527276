.dashboard-card .ant-card-body {
    display: flex;
    padding: 1.5rem 0.75rem;
    justify-content: space-between;
}

.dashboard-card {
    flex: 1;
    margin: 0px 10px;
}

.dashboard-card-info{
    margin-right: 30px;
    text-align: center;
}

.dashboard-container {
  justify-content: space-around;
}

.dashboard-card-head > div {
  margin-top: 10px;
}

.dashboard-card-icon {
  /* color: #45b6f5; */
  color: #c4313f;
  align-self: center;
  font-style: normal;
  font-weight: 300;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  padding-left: 10px;
  margin-right: 60px;
}
