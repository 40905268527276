.general-info-card {
  flex: 1;
}

.general-info-container {
  margin-top: 50px;
}

.general-info-card .ant-card-head-title {
  text-align: center;
  font-weight: bold;
}

.general-info-container .general-info-card:first-child .ant-card-head {
  color: #6394f9;
}

.general-info-container .general-info-card:nth-child(2) .ant-card-head {
  color: #7D63F9;
}

.general-info-container .general-info-card:nth-child(3) .ant-card-head {
  color: #63DFF9;
}

.pop-onboarding-decision {
  margin-top: 10px;
}

.nurse-item {
  cursor: pointer;
  padding: 10px 30px;
}

.nurse-item:hover {
  background-color: #f0f2f5;
}

.nurses-list-cotainer {
  max-width: 50%;
  margin: auto;
}
