.login-container {
  width: 100vw;
  justify-content: space-between;
  margin-top: 0px;
}

.login-logo-division {
  width: 50vw;
  justify-content: space-between;
  display: flex;
  height: 100vh;
  background-color: #b2c9e0;
}

.login-logo {
  max-width: 400px;
  max-height: 400px;
  margin: 23% auto;
}

.login-card {
  margin: 15% auto;
  height: min-content;
  width: 300px;
}
