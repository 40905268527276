.header {
  background-color: #fff !important;
  padding: 0 !important;
}

.header-top {
  box-shadow: 0 2px 8px #e2e5e8;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  padding: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.header-actions {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-right: 60px;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

#logo {
  height: 42px;
  padding-left: 40px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px;
  font-family: Avenir, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji", sans-serif;
  line-height: 64px;
  white-space: nowrap;
  text-decoration: none;
}

#logo img {
  position: relative;
  height: 42px;
}

.full-logo {
  margin-left: 45px;
  padding: 5px;
  max-width: 69px;
  margin-top: 10px;
}

@media (max-width: 600px) {
  .header-actions {
    margin-left: 12px;
    padding-right: 0px;
  }

  .header-top {
    -webkit-justify-content: unset;
            justify-content: unset;
  }
}

:root {
  --main-bg-color: #f0f2f5;
}

.app-content {
  margin: 30px;
  min-height: 78vh;
}

.header-title {
  color: #fff !important;
  text-align: center;
  line-height: inherit !important;
}

.table-action-button {
  margin: 0 5px;
}

.success-button {
  color: #5fc114;
  background-color: inherit;
  border: 1px solid #5fc114;
}

.success-button:hover {
  color: #83c84e;
  background-color: inherit;
  border: 1px solid #83c84e;
}

.icon-button {
  margin-right: 5px;
}

.flex {
  display: -webkit-flex;
  display: flex;
}

.flex-column {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.left-icon {
  margin-right: 5px;
}

.m-left-small {
  margin-left: 5px;
}

.m-right-small {
  margin-right: 5px;
}

.m-bottom-medium {
  margin-bottom: 14px;
}

.auth-layout {
  background-color: #fff;
}

.auth-footer {
  color: #fff;
  text-align: right;
  background-color: #b2c9e0;
  height: 80px;
}

.center-button {
  display: block;
  margin: auto;
}



.flex-wrap {
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

/* overRides  */
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45)
}

.ant-steps-item {
  -webkit-flex: none;
          flex: none;
  -ms-flex: none;
}

.timeline-item-consultations .ant-tree-list-holder-inner {
  background: #f0f2f5;
}

.sidebar-menu-item {
-webkit-align-items: center;
        align-items: center;
font-size: 12px;
padding: 4px !important;
}

.sidebar {
    background-color: #fafafc;
}

.sidebar .ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 52px;
    line-height: 40px;
}


.sidebar .ant-divider-horizontal {
    margin: 12px 0;
}
.dashboard-card .ant-card-body {
    display: -webkit-flex;
    display: flex;
    padding: 1.5rem 0.75rem;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.dashboard-card {
    -webkit-flex: 1 1;
            flex: 1 1;
    margin: 0px 10px;
}

.dashboard-card-info{
    margin-right: 30px;
    text-align: center;
}

.dashboard-container {
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.dashboard-card-head > div {
  margin-top: 10px;
}

.dashboard-card-icon {
  /* color: #45b6f5; */
  color: #c4313f;
  -webkit-align-self: center;
          align-self: center;
  font-style: normal;
  font-weight: 300;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  padding-left: 10px;
  margin-right: 60px;
}

.add-antecedent-card {
  /* max-width: 50%; */
  margin: auto;
}

.add-antecedent-card .ant-input {
  max-width: 50%;
}

.add-antecedent-card .ant-card-head-title {
  color: #fff;
  text-align: center;
}

.add-antecedent-card .ant-card-head {
  background: #6394f9;
}

.dmi-treatment-reminder {
  border: 1px solid #f0f0f0;
  padding: 10px;
  margin-bottom: 10px;
}


.rotate-0 .image-gallery-image {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.rotate-90 .image-gallery-image {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.rotate-180 .image-gallery-image {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.rotate-270 .image-gallery-image {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.image-gallery-slide {
  background-color: inherit;
  position: absolute;
  height: auto;
  top: inherit;
  left: inherit;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 120px);
}

.general-info-card {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.general-info-container {
  margin-top: 50px;
}

.general-info-card .ant-card-head-title {
  text-align: center;
  font-weight: bold;
}

.general-info-container .general-info-card:first-child .ant-card-head {
  color: #6394f9;
}

.general-info-container .general-info-card:nth-child(2) .ant-card-head {
  color: #7D63F9;
}

.general-info-container .general-info-card:nth-child(3) .ant-card-head {
  color: #63DFF9;
}

.pop-onboarding-decision {
  margin-top: 10px;
}

.nurse-item {
  cursor: pointer;
  padding: 10px 30px;
}

.nurse-item:hover {
  background-color: #f0f2f5;
}

.nurses-list-cotainer {
  max-width: 50%;
  margin: auto;
}

.login-container {
  width: 100vw;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 0px;
}

.login-logo-division {
  width: 50vw;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  display: -webkit-flex;
  display: flex;
  height: 100vh;
  background-color: #b2c9e0;
}

.login-logo {
  max-width: 400px;
  max-height: 400px;
  margin: 23% auto;
}

.login-card {
  margin: 15% auto;
  height: -webkit-min-content;
  height: min-content;
  width: 300px;
}

.consultation-type-form {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 44px;
}

.clinical-examination-form .ant-input-group-addon {
  color: #fff;
  background-color: #1890ff;
}

.clinical-examination-form {
  padding: 30px;
}

.clinical-examination-form input {
  max-width: 200px;
}
.clinical-examination-form .ant-input-group.ant-input-group-compact {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.prescription-form-container {
    min-width: 400px;
    max-width: 600px;
    margin: auto;
    margin-top: 24px;
}

#prescription-form {
  max-height: 400px;
  overflow: auto;
}



.patient-status-form .ant-form-item-label:first-child {
  text-align: left;
}

.consultation-type-form .ant-card-body {
  padding-bottom: 0;
}

.timeline-item-consultations {
  max-width: 60%;
margin: auto;
}
.message--sent {
  position: absolute;
  right: 3.15rem;
  bottom: -1.075rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.message--sent svg {
  margin-left: 0.25rem;
}

.message--sent-date {
  position: absolute;
  left: 3.75rem;
  bottom: -1.075rem;
}

.message-text-contact {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  border-radius: 0.625rem;
  padding: 0.625rem;
  font-size: 1rem;
  margin: -0.625rem 0 0.25rem 0.75rem;
  background-color: #f0f2f5;
  word-wrap: anywhere;
}

.message:not(:first-child) {
  margin-top: 2rem;
}
.message:first-child {
  margin-top: 1rem;
}

.message-text-user {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  border-radius: 0.625rem;
  padding: 0.625rem;
  margin: -0.625rem 0.75rem 0.25rem 0;
  font-size: 1rem;
  background-color: #1890ff;
  color: #fff;
  word-wrap: anywhere;
}

.message-user-receiver {
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

.message {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  position: relative;
}

.message > .ant-avatar.ant-avatar-icon {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
}

.contacts-list {
  max-height: 320px;
  overflow: auto;
}

.contacts-list .ant-list-item {
  cursor: pointer;
  padding-left: 10px;
  padding-top: 18px;
  border: 30px
}

.contacts-list .ant-list-item:hover {
  background: #d1d7e1;
}

.chat-tabs-container {
  max-width: 300px;
  -webkit-flex: 1 1;
          flex: 1 1;
}


.chat-tabs-container .ant-card-body {
  padding: 0;
}

.selected-contact {
  background: #D454601A;
}

.selected-contact:hover {
  background: #D454601A !important;
}

.chat-tabs-container .ant-tabs-nav-wrap {
  padding: 0 24px;
}

.unseen-message {
  margin-left: 8px;
  width: 0.6125rem;
  height: 0.6125rem;
  background-color: #ea4243;
  border-radius: 25px;
}

