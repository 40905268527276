.consultation-type-form {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 44px;
}

.clinical-examination-form .ant-input-group-addon {
  color: #fff;
  background-color: #1890ff;
}

.clinical-examination-form {
  padding: 30px;
}

.clinical-examination-form input {
  max-width: 200px;
}
.clinical-examination-form .ant-input-group.ant-input-group-compact {
  display: flex;
  align-items: center;
}

.prescription-form-container {
    min-width: 400px;
    max-width: 600px;
    margin: auto;
    margin-top: 24px;
}

#prescription-form {
  max-height: 400px;
  overflow: auto;
}



.patient-status-form .ant-form-item-label:first-child {
  text-align: left;
}

.consultation-type-form .ant-card-body {
  padding-bottom: 0;
}

.timeline-item-consultations {
  max-width: 60%;
margin: auto;
}